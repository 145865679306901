import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import { Box, Flex, Hide, Show, SimpleGrid } from '@chakra-ui/react';
import { usePageContext } from '../../context/PageContext';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { CTA } from '../CTA';
import { Heading } from '../Heading';
import { LinkCardNewsPost, LinkCardNewsPostProps } from '../LinkCardNewsPost';
import { NestedLocale } from '../NestedLocale';
import { TRANS } from './misc';

type SeeAllProps = { fullWidth?: boolean } & Pick<
  FeaturedNewsPostsProps,
  'componentLocale'
>;

const SeeAll = ({
  fullWidth,
  componentLocale,
}: SeeAllProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);
  const { newsCollectionPath } = usePageContext();

  return (
    <CTA
      as={GatsbyLink}
      to={newsCollectionPath}
      lang={lang}
      fullWidth={fullWidth}
    >
      {TRANS.seeAllPosts[locale]}
    </CTA>
  );
};

export type FeaturedNewsPostsProps = {
  componentLocale?: Locale;
  heading?: {
    en: string;
    fi: string;
  };
  posts?: LinkCardNewsPostProps[];
};

export const FeaturedNewsPosts = ({
  componentLocale,
  heading = {
    en: 'News',
    fi: 'Uutiset',
  },
  posts,
}: FeaturedNewsPostsProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return !posts?.length ? null : (
    <NestedLocale componentLocale={lang}>
      <Box key={'show'}>
        <Show above={'m'}>
          <Flex align={'flex-end'} justify={'space-between'}>
            <Heading level={3} as={'h2'} marginBottom={0}>
              {heading[locale]}
            </Heading>
            <SeeAll />
          </Flex>
          <SimpleGrid
            gap={'1.5rem'}
            gridTemplateColumns={`repeat(${Math.min(posts.length, 3)}, 1fr)`}
            marginTop={'1.5rem'}
            marginBottom={'1rem'}
          >
            {posts.map((post, i) => (
              <LinkCardNewsPost key={post.title + i} {...post} lines={6} />
            ))}
          </SimpleGrid>
        </Show>
      </Box>
      <Box key={'hide'}>
        <Hide above={'m'}>
          <Heading level={3} as={'h2'}>
            {heading[locale]}
          </Heading>
          <SimpleGrid
            gap={'1.5rem'}
            gridTemplateColumns={'1fr'}
            marginTop={'1.5rem'}
            marginBottom={'1rem'}
          >
            {posts.map((post, i) => (
              <LinkCardNewsPost key={post.title + i} {...post} lines={6} />
            ))}
          </SimpleGrid>
          <SeeAll fullWidth />
        </Hide>
      </Box>
    </NestedLocale>
  );
};
