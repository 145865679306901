export const TRANS = {
  seeAll: {
    en: 'See all',
    fi: 'Katso kaikki',
  },
  seeAllPosts: {
    en: 'See all news',
    fi: 'Katso kaikki uutiset',
  },
};
