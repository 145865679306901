import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import { Box, Flex, Hide, Show, SimpleGrid } from '@chakra-ui/react';
import { usePageContext } from '../../context/PageContext';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { CTA } from '../CTA';
import { Heading } from '../Heading';
import {
  LinkCardEventPost,
  LinkCardEventPostProps,
} from '../LinkCardEventPost';
import { NestedLocale } from '../NestedLocale';
import { TRANS } from './misc';

type SeeAllProps = { fullWidth?: boolean } & Pick<
  FeaturedEventPostsProps,
  'componentLocale'
>;

const SeeAll = ({
  fullWidth,
  componentLocale,
}: SeeAllProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);
  const { eventCollectionPath } = usePageContext();

  return (
    <CTA
      as={GatsbyLink}
      to={eventCollectionPath}
      lang={lang}
      fullWidth={fullWidth}
    >
      {TRANS.seeAllPosts[locale]}
    </CTA>
  );
};

export type FeaturedEventPostsProps = {
  componentLocale?: Locale;
  heading?: {
    en: string;
    fi: string;
  };
  posts?: LinkCardEventPostProps[];
};

export const FeaturedEventPosts = ({
  componentLocale,
  heading = {
    en: 'Events',
    fi: 'Tapahtumat',
  },
  posts,
}: FeaturedEventPostsProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return !posts?.length ? null : (
    <NestedLocale componentLocale={lang}>
      <Box key={'show'}>
        <Show above={'m'}>
          <Flex align={'flex-end'} justify={'space-between'}>
            <Heading level={3} as={'h2'} marginBottom={0}>
              {heading[locale]}
            </Heading>
            <SeeAll />
          </Flex>
          <SimpleGrid gap={'1.5rem'} marginTop={'1.5rem'} marginBottom={'1rem'}>
            {posts.map((post, i) => (
              <LinkCardEventPost
                key={post.slug + i}
                {...post}
                variant={'horizontal'}
              />
            ))}
          </SimpleGrid>
        </Show>
      </Box>
      <Box key={'hide'}>
        <Hide above={'m'}>
          <Heading level={3} as={'h2'}>
            {heading[locale]}
          </Heading>
          <SimpleGrid gap={'1.5rem'} marginTop={'1.5rem'} marginBottom={'1rem'}>
            {posts.map((post, i) => (
              <LinkCardEventPost
                key={post.slug + i}
                {...post}
                variant={'horizontal'}
              />
            ))}
          </SimpleGrid>
          <SeeAll fullWidth />
        </Hide>
      </Box>
    </NestedLocale>
  );
};
