import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { useSortedEvents } from '../../utils/hooks';
import { LinkCardEventPostProps } from '../LinkCardEventPost';
import { FeaturedEventPosts } from '.';

type UpcomingEventPostsSubquery = {
  nodes: LinkCardEventPostProps[];
};

type UpcomingEventPostsQuery = {
  contentfulPageFrontpage: {
    heroCta?: {
      contentful_id: string;
    };
  };
  en: UpcomingEventPostsSubquery;
  fi: UpcomingEventPostsSubquery;
};

export const UpcomingEventPosts = (): ReactElement => {
  const data: UpcomingEventPostsQuery = useStaticQuery(query);
  const { locale } = usePageContext();
  const posts = data[locale].nodes;
  const idToExclude = data.contentfulPageFrontpage.heroCta?.contentful_id;
  const { upcoming } = useSortedEvents(posts, idToExclude);

  return (
    <FeaturedEventPosts
      posts={upcoming.slice(0, 3)}
      heading={{
        en: 'Upcoming events',
        fi: 'Tulevat tapahtumat',
      }}
    />
  );
};

const query = graphql`
  {
    contentfulPageFrontpage {
      heroCta {
        ... on ContentfulEntry {
          contentful_id
        }
      }
    }
    en: allContentfulPageEventPost(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: start, order: DESC }
    ) {
      nodes {
        contentful_id
        ... on ContentfulPageEventPost {
          ...EventPostLink
        }
      }
    }
    fi: allContentfulPageEventPost(
      filter: { node_locale: { eq: "fi" } }
      sort: { fields: start, order: DESC }
    ) {
      nodes {
        contentful_id
        ... on ContentfulPageEventPost {
          ...EventPostLink
        }
      }
    }
  }
`;
