import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { UpcomingEventPosts } from '../components/FeaturedEventPosts/withQueryUpcoming';
import { LatestNewsPosts } from '../components/FeaturedNewsPosts/withQueryLatest';
import { Hero, HeroProps } from '../components/Hero';
import { ImageRasterProps } from '../components/ImageRaster';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { RichText, RichTextData } from '../components/RichText';
import { Main } from '../components/Main';
import { usePageContext } from '../context/PageContext';
import { ColorScheme } from '../theme/colors';

export type FrontpageQuery = {
  contentfulPageFrontpage: {
    title: string;
    description?: string;
    heroColorScheme?: ColorScheme;
    heroHeader?: string;
    heroBody?: RichTextData;
    heroCta?: {
      __typename: string;
      slug?: string;
      url?: string;
    };
    heroCtaText?: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    content?: RichTextData;
  };
};

export const Frontpage = ({
  data,
}: PageProps<FrontpageQuery>): ReactElement => {
  const { locale, eventCollectionPath, newsCollectionPath } = usePageContext();
  const {
    title,
    heroColorScheme = 'pink',
    heroHeader,
    heroBody,
    heroCta,
    heroCtaText,
    image,
    content,
  } = data.contentfulPageFrontpage;
  const heroProps: HeroProps = {
    heroColorScheme,
    heroHeader,
    heroBody,
    heroCtaText,
    heroCtaUrl:
      !heroCta?.url && !heroCta?.slug
        ? undefined
        : heroCta.url
        ? heroCta.url
        : heroCta.__typename === 'ContentfulPageEventPost'
        ? `${eventCollectionPath}/${heroCta.slug}`
        : heroCta.__typename === 'ContentfulPageNewsPost'
        ? `${newsCollectionPath}/${heroCta.slug}`
        : `/${locale}/${heroCta.slug}`,
    heroImage: image,
    variant: 'wide',
  };

  return (
    <Main
      variant={'wide'}
      pageTitle={title}
      hero={<Hero {...heroProps} />}
      hidePageTitle={!!heroHeader}
    >
      <UpcomingEventPosts />
      <Box height={'1em'} />
      <LatestNewsPosts />
      {content && <RichText data={content} />}
    </Main>
  );
};

export default Frontpage;

export const query = graphql`
  query ($id: String!) {
    contentfulPageFrontpage(id: { eq: $id }) {
      ...Frontpage
    }
  }
`;
