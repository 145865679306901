import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { LinkCardNewsPostProps } from '../LinkCardNewsPost';
import { FeaturedNewsPosts } from '.';

type LatestNewsPostsSubquery = {
  nodes: LinkCardNewsPostProps[];
};

type LatestNewsPostsQuery = {
  en: LatestNewsPostsSubquery;
  fi: LatestNewsPostsSubquery;
};

export const LatestNewsPosts = (): ReactElement => {
  const data: LatestNewsPostsQuery = useStaticQuery(query);
  const { locale } = usePageContext();

  return (
    <FeaturedNewsPosts
      posts={data[locale].nodes}
      heading={{
        en: 'Latest news',
        fi: 'Viimeiset uutiset',
      }}
    />
  );
};

const query = graphql`
  {
    en: allContentfulPageNewsPost(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        ... on ContentfulPageNewsPost {
          ...NewsPostLink
        }
      }
    }
    fi: allContentfulPageNewsPost(
      filter: { node_locale: { eq: "fi" } }
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        ... on ContentfulPageNewsPost {
          ...NewsPostLink
        }
      }
    }
  }
`;
